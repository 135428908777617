import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { Upload } from 'assets/icons/Upload'
import { CKEditorField } from 'components/FormFields/CKEditor/CkEditorField'
import { CurrencyField } from 'components/FormFields/CurrencyField'
import { InputField } from 'components/FormFields/InputField'
import { SelectField } from 'components/FormFields/SelectField'
import { StatusField } from 'components/FormFields/StatusField'
import { UploadField } from 'components/FormFields/UploadField'
import { auth } from 'constants/common'
import { languageOptions } from 'constants/language'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const schema = yup.object().shape({
    GenresName: yup.string().required('Genre name is required'),
})

export function AddEditGenreForm({
    data,
    isEdit,
    onSubmit,
    onLanguagechange,
    language,
}) {
    const { t } = useTranslation()
    const [imageUrl, setImageUrl] = useState('')
    const { control, handleSubmit, setValue } = useForm({
        defaultValues: {
            GenresName: '',
            LanguagesID: language || 'vi-VN',
            StatusID: true,
            ShortDescription: '',
            Description: '',
            Amount: '',
        },

        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (data) {
            const newData = {
                ...data,
                StatusID: Boolean(data?.StatusID),
            }
            Object.keys(newData).forEach((key) => {
                setValue(key, newData[key])
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (data && data.ImagesPaths) {
            setImageUrl(data.ImagesPaths)
        }
    }, [data])

    const handleFormSubmit = handleSubmit((formValues) => {
        const formData = {
            auth,
            data: {
                ...formValues,
                StatusID: formValues.StatusID ? 1 : 0,
            },
        }
        onSubmit?.(formData)
    })

    return (
        <Stack
            component="form"
            spacing={3}
            noValidate
            onSubmit={handleFormSubmit}
        >
            <Paper elevation={3}>
                <Box p={3}>
                    <Typography variant="h6" fontWeight="bold">
                        {t('Detail')}
                    </Typography>

                    <Typography variant="body2">
                        {`${t('Genre Name')}, ${t('language')}, ${t(
                            'status',
                        )},...`}
                    </Typography>
                </Box>

                <Divider />

                <Stack spacing={3} p={3}>
                    <Box>
                        <Typography variant="caption">{t('status')}</Typography>
                        <StatusField name="StatusID" control={control} />
                    </Box>

                    <Box>
                        <InputField
                            name="GenresName"
                            label={t('Genre Name')}
                            control={control}
                        />
                    </Box>

                    <Box>
                        <CurrencyField
                            name="Amount"
                            label={t('Unit Price')}
                            control={control}
                        />
                    </Box>

                    <Box>
                        <SelectField
                            name="LanguagesID"
                            label={t('language')}
                            control={control}
                            optionList={languageOptions}
                            onChange={(value) => onLanguagechange?.(value)}
                        />
                    </Box>

                    {/* <Box
                        sx={{
                            '& textarea': {
                                whiteSpace: 'pre-wrap',
                            },
                        }}
                    >
                        <InputField
                            name="ShortDescription"
                            label={t('ShortDescription')}
                            control={control}
                            multiline
                            rows={3}
                            maxLength={300}
                        />
                    </Box> */}

                    <Box>
                        <CKEditorField
                            name="Description"
                            label={t('description')}
                            control={control}
                        />
                    </Box>

                    <Box>
                        <UploadField
                            name="ImagesPaths"
                            control={control}
                            onUploadChange={(value) => {
                                console.log(value)
                                setImageUrl(value)
                            }}
                        >
                            <Typography variant="caption" gutterBottom>
                                {t('uploadImage')}
                            </Typography>

                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                }}
                            >
                                {imageUrl ? (
                                    <Box
                                        component="img"
                                        src={imageUrl}
                                        alt="avatar"
                                        sx={{
                                            width: '50%',
                                            aspectRatio: '504/707',
                                            objectFit: 'cover',
                                        }}
                                    />
                                ) : (
                                    <Upload />
                                )}
                            </Stack>
                        </UploadField>
                    </Box>
                </Stack>

                <Divider />

                <Stack direction="row" justifyContent="flex-end" p={3}>
                    <Button variant="contained" type="submit">
                        {isEdit ? t('Update') : t('Create')}
                    </Button>
                </Stack>
            </Paper>
        </Stack>
    )
}
