import { FormControl, FormControlLabel, Switch } from '@mui/material'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'

StatusField.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.any.isRequired,
    label: PropTypes.node,
}

export function StatusField({ name, control, label, ...others }) {
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
    })

    return (
        <FormControl sx={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={value}
                        value={value}
                        onChange={onChange}
                        {...others}
                    />
                }
                label={label}
            />
        </FormControl>
    )
}
