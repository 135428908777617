import { Box, FormControl, FormHelperText } from '@mui/material'
import { uploadApi } from 'api/uploadApi'
import { useController } from 'react-hook-form'
export function UploadField({
    children,
    name,
    control,
    onUploadChange,
    ...props
}) {
    const {
        field: { onChange },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    })

    async function handleChange(e) {
        const file = e.target.files?.[0]

        if (!file) {
            return
        }

        const formData = new FormData()
        formData.append('', file)

        try {
            const res = await uploadApi.uploadAvatar(formData)

            if (res) {
                onChange(res)
                onUploadChange(res)
            }
        } catch (error) {
            console.error(`${error}`)
        }
    }

    return (
        <FormControl fullWidth error={invalid}>
            <Box component="label" {...props}>
                {children}

                <Box
                    component="input"
                    onChange={handleChange}
                    type="file"
                    sx={{ display: 'none' }}
                />
                {invalid && (
                    <FormHelperText error>{error.message}</FormHelperText>
                )}
            </Box>
        </FormControl>
    )
}
