import { LinearProgress } from '@mui/material'
import NotFound from 'app/NotFound/NotFound'
import { DashboardLayout } from 'components/Layouts/DashboardLayout'
import { Suspense } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Event from './Events/Events'
import Genres from './Genres/Genres'
import News from './News/News'
import Players from './Players/Players'
import Staffs from './Staffs/Staffs'
import Screen from './Screen/Screen'

export function Dashboard() {
    return (
        <DashboardLayout>
            <Suspense fallback={<LinearProgress />}>
                <Routes>
                    <Route index element={<Navigate to="events" />} />
                    <Route path="events/*" element={<Event />} />
                    <Route path="genres/*" element={<Genres />} />
                    <Route path="news/*" element={<News />} />
                    <Route path="staffs/*" element={<Staffs />} />
                    <Route path="players/*" element={<Players />} />
                    <Route path="screen/*" element={<Screen />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Outlet />
            </Suspense>
        </DashboardLayout>
    )
}
