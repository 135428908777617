import { SearchBox } from 'components/FormFields/SearchBox'
import { SortBox } from 'components/FormFields/SortBox'
import { Box, Button, Stack } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

export function PlayerFilter({
    params,
    onFilterChange,
    eventList = [],
    genreList = [],
    onExport,
}) {
    function handleSearchChange(value) {
        onFilterChange({ ...params, key: value })
    }
    function handleChangeEventID(value) {
        onFilterChange({ ...params, eventID: value })
    }
    function handleChangeGenresID(value) {
        onFilterChange({ ...params, genresID: value })
    }

    return (
        <Stack direction="row" justifyContent="flex-end" sx={{ mx: -1 }}>
            <Box>
                <Box sx={{ p: 1 }}>
                    <SearchBox onSearchChange={handleSearchChange} />
                </Box>
            </Box>

            <Box sx={{ p: 1 }}>
                <SortBox
                    label="Sort By Events"
                    onChange={handleChangeEventID}
                    defaultValue="0"
                    hideOptionAll
                    optionList={
                        eventList.length > 0
                            ? eventList.concat([
                                  {
                                      label: 'All',
                                      value: '0',
                                  },
                              ])
                            : []
                    }
                />
            </Box>
            <Box sx={{ p: 1 }}>
                <SortBox
                    label="Sort By Genres"
                    onChange={handleChangeGenresID}
                    defaultValue="0"
                    hideOptionAll
                    optionList={
                        genreList.length > 0
                            ? genreList.concat([{ label: 'All', value: '0' }])
                            : []
                    }
                />
            </Box>

            <Box sx={{ p: 1 }}>
                <Button
                    variant="contained"
                    startIcon={<FileDownloadIcon />}
                    onClick={() => onExport?.()}
                >
                    Export
                </Button>
            </Box>
        </Stack>
    )
}
