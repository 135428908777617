import CheckIcon from '@mui/icons-material/Check'
import {
    Box,
    Button,
    createTheme,
    CssBaseline,
    Stack,
    ThemeProvider,
    Typography,
} from '@mui/material'
import { InputField } from 'components/FormFields/InputField'
import { SliderField } from 'components/FormFields/SliderField'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { getToken } from 'utils/hash'

export function ScoringForm({ onSubmit, data, isSelected }) {
    const [point, setPoint] = useState(data?.PointPlay || 0)
    const { control, handleSubmit } = useForm({
        defaultValues: {
            PointPlay: data?.PointPlay || 0,
        },
    })
    const token = getToken()

    const handleFormSubmit = handleSubmit((formValues) => {
        const newData = {
            auth: {
                UserID: token?.UserID,
                UUSerID: token?.UserName,
            },
            data: {
                RegisterPlayGenresID: data.RegisterPlayGenresID,
                PointPlay: formValues.PointPlay,
            },
        }
        onSubmit?.(newData)
    })

    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
    })

    return (
        <Stack
            direction="row"
            alignItems="center"
            component="form"
            spacing={1}
            onSubmit={handleFormSubmit}
            noValidate
        >
            <Stack
                direction="row"
                alignItems="center"
                boxShadow={1}
                sx={{
                    flexGrow: 1,
                    height: 90,
                    borderRadius: '4px',

                    bgcolor: isSelected ? '#001119' : '#004a6d',
                    color: 'white',
                    cursor: 'pointer',

                    '&:hover': {
                        bgcolor: '#001119',
                        boxShadow: (theme) => theme.shadows[10],
                    },
                }}
            >
                <Stack direction="row" alignItems="center" width="100%">
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            p: 1,
                            height: 90,
                            borderRadius: '4px 0 0 4px',
                            bgcolor: '#0076ab',
                            mr: 2,
                        }}
                    >
                        <Typography>{data?.idx}</Typography>
                    </Stack>

                    <Box
                        component="img"
                        sx={{
                            width: 40,
                            aspectRatio: '26/20',
                            mr: 2,
                        }}
                        src={`https://flagpedia.net/data/flags/w702/${data.Flag.toLowerCase()}.webp`}
                        alt="vn"
                    />

                    <Box flexGrow={1}>
                        <Typography textTransform="uppercase" fontWeight={600}>
                            {data?.FullName}
                        </Typography>
                    </Box>

                    <Box width={1 / 2}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />

                            <Stack direction="row" alignItems="center">
                                <Box flexGrow={1}>
                                    <SliderField
                                        onChange={(value) => setPoint(value)}
                                        step={0.5}
                                        min={0}
                                        max={100}
                                        name="PointPlay"
                                        control={control}
                                        disabled={Boolean(data?.PointPlay)}
                                    />
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        sx={{
                                            '.MuiInputBase-input': {
                                                p: 1,
                                            },
                                        }}
                                    >
                                        <Typography variant="body2">
                                            SCORE
                                        </Typography>{' '}
                                        <InputField
                                            disabled={Boolean(data?.PointPlay)}
                                            type="number"
                                            size="small"
                                            name="PointPlay"
                                            step={0.5}
                                            max={100}
                                            min={0}
                                            sx={{ width: 70 }}
                                            control={control}
                                            onChange={(value) =>
                                                setPoint(value)
                                            }
                                        />
                                    </Stack>
                                </Box>

                                <Typography
                                    textTransform="uppercase"
                                    fontWeight={600}
                                    variant="h4"
                                    align="right"
                                    sx={{ p: 2, width: 100 }}
                                >
                                    {point}
                                </Typography>
                            </Stack>
                        </ThemeProvider>
                    </Box>
                </Stack>
            </Stack>

            <Button
                type="submit"
                disabled={Boolean(data?.PointPlay)}
                sx={{
                    height: '90px',
                    width: 95,
                    p: 2,

                    bgcolor: '#004a6d',
                    cursor: 'pointer',

                    '&:hover': {
                        bgcolor: '#001119',
                        boxShadow: (theme) => theme.shadows[10],
                    },
                }}
            >
                <Typography variant="h5" color="white" fontWeight={600}>
                    {data?.PointPlay ? (
                        <CheckIcon sx={{ fontSize: 64 }} />
                    ) : (
                        'SAVE'
                    )}
                </Typography>
            </Button>
        </Stack>
    )
}
