import { Box, Button, Stack, Typography } from '@mui/material'
import { CheckIcon } from 'assets/icons/CheckIcon'
import { CountDownTimer } from 'components/Common/CountDownTime'
import { createRef, useEffect, useState } from 'react'
import divider from 'assets/images/divider.png'
// import player_1 from 'assets/images/player-1.png'
// import player_2 from 'assets/images/player-2.png'

export function RoundPlayer({ data, showResult, defaultTime }) {
    const [playerStart, setPlayerStart] = useState(0)
    const [time, setTime] = useState(0)
    const newData = data?.CoupleDetail
    const timeRef = createRef()
    const isWin = newData?.IsWin || 0

    useEffect(() => {
        if (!timeRef.current) return

        const interval = setInterval(() => {
            if (timeRef.current) {
                const newTime = timeRef.current.getTime()
                setTime(newTime)
                newTime === 0 && setPlayerStart(0)
            }

            if (time === undefined) {
                timeRef.current = new CountDownTimer()
            }
        }, 1000)
        return () => clearInterval(interval)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeRef])

    return (
        <Stack
            direction="row"
            // alignItems="center"
            justifyContent="space-between"
            height="100%"
            spacing={5}
            sx={{
                width: '100%',
                px: 12.5,
                mt: 5,
                mb: 3,
            }}
        >
            <Box
                sx={{
                    width: 2 / 5,
                }}
            >
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    zIndex={1}
                    spacing={2}
                >
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: '100%',
                            overflow: 'hidden',
                            aspectRatio: '16/9',
                            border: '3px solid #fff',
                            borderRadius: '2px',
                            bgcolor: '#0e4500',
                        }}
                    >
                        <Box
                            width="100%"
                            height="100%"
                            component="img"
                            src={data?.CoupleDetail?.ImagesPath}
                            alt="player"
                            sx={{
                                objectFit: 'contain',
                                filter:
                                    showResult && isWin === 1
                                        ? 'drop-shadow(0px 0px 10px #ffffff)'
                                        : '',
                            }}
                        />
                    </Stack>

                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            backgroundImage: `url(${divider})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',

                            width: '100%',
                            mx: 'auto',
                            textAlign: 'center',
                            color: '#ffba00',
                            textTransform: 'uppercase',
                            aspectRatio: 671 / 44,
                        }}
                    >
                        {data?.CoupleDetail?.FullName}
                    </Stack>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isWin !== 0}
                        onClick={() => {
                            setPlayerStart(1)
                            timeRef.current?.reset()
                            timeRef.current?.start()
                        }}
                        size="large"
                        startIcon={
                            playerStart === 1 || isWin !== 0 ? (
                                <CheckIcon />
                            ) : (
                                '0'
                            )
                        }
                        sx={{
                            width: '60%',
                            '&.Mui-disabled': {
                                color: 'white',
                                backgroundColor: 'primary.main',
                                boxShadow:
                                    showResult && isWin === 1
                                        ? `0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 25px #fff, 0 0 30px #fff, 0 0 35px #fff, 0 0 40px #fff`
                                        : 'none',
                            },

                            boxShadow:
                                playerStart === 1
                                    ? `0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 25px #fff, 0 0 30px #fff, 0 0 35px #fff, 0 0 40px #fff`
                                    : 'none',
                        }}
                    />
                </Stack>
            </Box>

            <Stack
                alignItems="center"
                justifyContent="flex-end"
                sx={{ width: 1 / 5 }}
            >
                {!showResult || isWin === 0 ? (
                    <>
                        <CountDownTimer
                            ref={timeRef}
                            variant="h2"
                            defaultTime={defaultTime}
                            sx={{ color: 'white', fontWeight: 700 }}
                        />

                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => timeRef.current?.pause()}
                            >
                                Pause
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    timeRef.current?.pause()
                                    timeRef.current?.reset()
                                    setPlayerStart(0)
                                }}
                            >
                                Reset
                            </Button>
                        </Stack>
                    </>
                ) : (
                    <Typography
                        variant="h3"
                        color="white"
                        textAlign="center"
                        sx={{
                            pb: 2,
                            fontWeight: 900,
                            fontFamily: 'Oswald !important',
                            animation: 'scaleText 1s',
                            '@keyframes scaleText': {
                                '0%': {
                                    transform: 'scale(0)',
                                },
                                '100%': {
                                    transform: 'scale(1)',
                                },
                            },
                        }}
                    >
                        <Box>Winner</Box>
                        <Box
                            sx={{
                                animation:
                                    'textShadow 3s ease-in-out infinite alternate',

                                '@keyframes textShadow': {
                                    '0%': {
                                        textShadow:
                                            '0 0 10px #0095eb, 0 0 20px #0095eb, 0 0 30px #0095eb',
                                    },
                                    '50%': {
                                        textShadow:
                                            '0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffd700',
                                    },
                                    '100%': {
                                        textShadow:
                                            '0 0 10px #ff0095, 0 0 20px #ff0095, 0 0 30px #ff0095',
                                    },
                                },
                            }}
                        >
                            {isWin === 1
                                ? newData?.FullName
                                : isWin === 2
                                ? newData?.FullName_1
                                : ''}
                        </Box>
                    </Typography>
                )}
            </Stack>

            <Box
                sx={{
                    width: 2 / 5,
                }}
            >
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    zIndex={1}
                    spacing={2}
                    sx={{
                        width: '100%',
                    }}
                >
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: '100%',
                            overflow: 'hidden',
                            aspectRatio: '16/9',
                            border: '3px solid #fff',
                            borderRadius: '2px',
                            bgcolor: '#0e4500',
                        }}
                    >
                        <Box
                            component="img"
                            width="100%"
                            height="100%"
                            src={data?.CoupleDetail?.ImagesPath_1}
                            alt="player"
                            sx={{
                                objectFit: 'contain',
                                filter:
                                    showResult && isWin === 2
                                        ? 'drop-shadow(0px 0px 10px #ffffff)'
                                        : '',
                            }}
                        />
                    </Stack>

                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            backgroundImage: `url(${divider})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',

                            width: '100%',
                            mx: 'auto',
                            textAlign: 'center',
                            color: '#ffba00',
                            textTransform: 'uppercase',
                            aspectRatio: 671 / 44,
                        }}
                    >
                        {data?.CoupleDetail?.FullName_1}
                    </Stack>
                    <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setPlayerStart(2)
                            timeRef.current?.reset()
                            timeRef.current?.start()
                            setTime(0)
                        }}
                        size="large"
                        startIcon={
                            playerStart === 2 || isWin !== 0 ? (
                                <CheckIcon />
                            ) : (
                                '0'
                            )
                        }
                        disabled={newData && newData.IsWin !== 0}
                        sx={{
                            width: '60%',
                            '&.Mui-disabled': {
                                color: 'white',
                                backgroundColor: 'error.main',

                                boxShadow:
                                    showResult && isWin === 2
                                        ? `0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 25px #fff, 0 0 30px #fff, 0 0 35px #fff, 0 0 40px #fff,0 0 45px #fff, 0 0 50px #fff`
                                        : 'none',
                            },
                            boxShadow:
                                playerStart === 2
                                    ? `0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 25px #fff, 0 0 30px #fff, 0 0 35px #fff, 0 0 40px #fff`
                                    : 'none',
                        }}
                    />
                </Stack>
            </Box>
        </Stack>
    )
}
