import axiosClient from './axiosClient'

const url = 'registerplay'

export const registerPlayApi = {
    getAll(params) {
        return axiosClient.get(`${url}/getlist`, { params })
    },
    payment(body) {
        return axiosClient.post(`${url}/directpayment`, body)
    },
    updateStatus(body) {
        return axiosClient.post(`${url}/updatestatusid`, body)
    },

    getAllPlayerActive(params) {
        return axiosClient.get(
            `${url}/getlistuser-by-eventid-genreid-statusid`,
            { params },
        )
    },
    updatePointPlayer(body) {
        return axiosClient.post(`${url}/updatepointplay`, body)
    },

    getAllJudgePoint(params) {
        return axiosClient.get(`${url}/getlistjudgepoint`, { params })
    },
    getRanking(params) {
        return axiosClient.get(
            `${url}/getlistuser-by-eventid-genreid-bypoint`,
            { params },
        )
    },
    getAllRankingResult(params) {
        return axiosClient.get(`${url}/getrankingresult`, { params })
    },

    addEventAndGenreForUser(body) {
        return axiosClient.post(`${url}/addcustomerinvite`, body)
    },
    removeGuestOutEvent(body) {
        return axiosClient.post(`${url}/deletecustomerinvite`, body)
    },
}
